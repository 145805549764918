import Experiences from '../../components/experiences/experiences.vue'
import NoWebBanner from '../../components/no-web-banner/no-web-banner.vue'
import GalleryBuilderExp from '../../components/gallery-builder-exp/gallery-builder-exp.vue'

//custom services
import ExperienceService from '../../services/experience';
import GlobalService from '../../services/global';
import OpenService from '../../services/open';

export default {
  name: 'Experience',

  components: {
    Experiences,
    NoWebBanner,
    GalleryBuilderExp
  },

  methods: {
    hideModal() {
      this.showModal = false;
      this.$router.replace({ name: 'experiences'});
    },

    fetchExperience() {
      this.loading = this.showModal = this.fetchingUser = this.fetchingReviews = true;
      return ExperienceService
        .getExperience(this.id)
        .then(response => {
          
          let experience = response.data;
          experience.uname = 'l';
          this.experience = ExperienceService.mapExperience(experience);
          document.title = this.experience.title;
          this.loading = this.showModal = false;

          this.fetchUser(this.id);
          
          if (this.experience.totalReviews) this.fetchReviews(this.id);
          else this.fetchingReviews = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        }); 
    },

    fetchUser(id) {
      return ExperienceService
        .fetchUser(id)
        .then(resp => {
          let user = resp.data;
          
          let firstLetter = user.name.charAt(0).toLowerCase();
          user.photo = {
            img: GlobalService.getUserPhoto(user.photos, firstLetter),
            lazy: GlobalService.getLazy(firstLetter) 
          }

          this.experience.user = user;
          this.fetchingUser = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingUser = false;
        });
    },

    fetchReviews(id) {
      return ExperienceService
        .fetchReviews(id)
        .then(resp => {
          this.experience.reviews = resp.data;
          this.fetchingUser = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingUser = false;
        });
    },

    zoom() {
      window.open('https://zoom.us/download', '_blank');
    },

    skype() {
      window.open('https://www.skype.com/en/get-skype/', '_blank');
    },

    googleHangouts() {
      window.open('https://apps.google.com/meet/', '_blank');
    },

    generateSlug(string) {
      return OpenService.generateSlug(string);
    }
  },

  mounted() {
    this.showModal = true;
    this.fetchExperience(this.id);
  },

  watch: {
    '$route'(to) {
      if (to.params.id != this.id) {
        this.id = to.params.id;
        window.scrollTo(0,0);
        this.fetchExperience();
      }
    }
  },

  data() {
    return {
      loading: false,
      showModal: false,

      fetchingUser: false,
      fetchingReviews: false,

      id: this.$route.params.id || '',
      experience: {}
    }
  }
}