<template>
  <v-alert color="error" style="color: white;">
    <b-row>
      <b-col cols="7">
        <span v-if="text">{{ text }}</span>
        <span v-else>Please note that currently, we are not accepting bookings from the website. To book an e-experience, please download the community app on your mobile phone or tablet from <b>Google Play</b> or <b>App Store</b></span>
      </b-col>
      <b-col></b-col>
      <b-col cols="2" class="vc text-right">
        <a><img @click="googlePlay" src="@/assets/imgs/google-play-badge.png" alt="localites-android-app" width="160"></a>
      </b-col>
      <b-col cols="2" class="vc text-right">
        <a><img @click="appStore" src="@/assets/imgs/app-store-badge.png" alt="localites-ios-app" width="150"></a>
      </b-col>
    </b-row>
  </v-alert>
</template>

<script>
import config from '../../config';

export default {

  props: [
    'text'
  ],

  methods: {
    googlePlay() {
      window.open(config.googlePlay, '_blank');
    },

    appStore() {
      window.open(config.appStore, '_blank');
    }
  }
}
</script>