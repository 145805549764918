<template>
  <section class="exp-images">

    <v-dialog v-model="openGallery" max-width="500px">
      <v-carousel hide-delimiters interval="2500" height="600px">
        <v-carousel-item
          v-for="(item,i) in photos"
          :key="i"
          :src="item.img"
          :lazy-src="lazy"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>

    <b-row v-if="photos.length === 1" class="nomp">
      <b-col class="nomp" cols="12">
        <v-img @click="openGallery = true" class="item-image item-first noml nomr" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 2" class="nomp">
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image item-first-21 noml" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image item-first-22 nomr" :src="photos[1].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 3" class="nomp">
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image item-first-21 noml" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image" :src="photos[1].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image item-first-22 nomr" :src="photos[2].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 4" class="nomp">
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image item-first-21 noml" :src="photos[0].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image" :src="photos[1].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image" :src="photos[2].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image item-first-22 nomr" :src="photos[3].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>

      <!-- <b-row style="max-width: 350px; margin: auto; margin-top: -185px;">
        <b-col class="nomp">
          <v-img class="item-image user-profile-pic" :src="photos[3].img" :lazy-src="lazy" height="340"></v-img>
        </b-col>
      </b-row> -->
    </b-row>

    <b-row v-if="photos.length === 5" class="nomp">
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image item-first-21 noml" :src="photos[0].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image" :src="photos[1].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[2].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[3].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image item-first-22 nomr" :src="photos[4].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 6" class="nomp">
      <b-col class="nomp" cols="12">
        <v-img @click="openGallery = true" class="item-image item-first noml nomr" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[1].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image " :src="photos[2].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[3].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[4].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[5].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 7" class="nomp">
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image item-first-21 noml" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image item-first-22 nomr" :src="photos[1].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[2].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image " :src="photos[3].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[4].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[5].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[6].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 8" class="nomp">
      <b-col class="nomp" cols="12">
        <v-img @click="openGallery = true" class="item-image item-first noml nomr" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[1].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image " :src="photos[2].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[3].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[4].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[5].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[6].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="6">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[7].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
    </b-row>

    <b-row v-if="photos.length === 9" class="nomp">
      <b-col class="nomp" cols="12">
        <v-img @click="openGallery = true" class="item-image item-first nomr noml" :src="photos[0].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[1].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image" :src="photos[2].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="3">
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[3].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
        <b-row class="nomp">
          <b-col cols="12" class="nomp">
            <v-img @click="openGallery = true" class="item-image" :src="photos[4].img" :lazy-src="lazy" height="220"></v-img>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="nomp" cols="3">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[5].img" :lazy-src="lazy" height="450"></v-img>
      </b-col>
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image noml" :src="photos[6].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image" :src="photos[7].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
      <b-col class="nomp" cols="4">
        <v-img @click="openGallery = true" class="item-image nomr" :src="photos[8].img" :lazy-src="lazy" height="400"></v-img>
      </b-col>
    </b-row>
  </section>
</template>

<style>
  .item-first-21 {
    border-top-left-radius: 25px;
  }
  .item-first-22 {
    border-top-right-radius: 25px;
  }
  .user-profile-pic {
    border: 10px solid black;
    border-radius: 50%;
  }
  .item-image {
    cursor: pointer;
  }
</style>

<script>
  export default {
    name: 'GalleryBuilderExp',

    data() {
      return {
        openGallery: false,
        lazy: require("@/assets/imgs/placeholder.png")
      }
    },

    props: [
      'photos'
    ]
  }
</script>